module.exports={
"label.account.name":`Primeiro nome`,
"label.account.copy":`Por favor, copie o código de verificação aleatória no email, e clique no link para alterar a senha na próxima etapa.`,
"label.account.phone.length":`O número móvel requer pelo menos 7 caracteres`,
"label.account.Start.trial":`FIQUE ATENTO`,
"label.account.employees1000":`Mais de 1000 funcionários`,
"label.account.other.Ways":`Outros métodos de login`,
"label.account.emailsync.button.cancel":`Cancelar`,
"label.account.rule":`Consiste de 816 dígitos e letras.`,
"label.account.password.update.success":`A senha foi alterada com sucesso.`,
"label.account.describe":`O CloudCC concentrase na personalização digital, inteligência analítica e conectividade global, e é personalizado sob demanda juntamente com o desenvolvimento de negócios empresariais.`,
"label.account.enter.Emailbox":`Por favor, digite seu endereço de email.`,
"label.account.password":`Senha`,
"label.account.problem":`O login falhou.`,
"label.account.Complete.reset":`Email. Favor digitar o código de verificação para completar a redefinição da senha.`,
"label.account.crm.used":`CRM utilizado por empresas listadas`,
"label.account.report.loading":``,
"label.account.notcustomer":`Sem conta?`,
"label.account.employees501":`5011000 funcionários`,
"label.account.title.operationManager":`Gerente de operações`,
"label.account.Preset":`Ferramentas prédefinidas de gerenciamento de projetos`,
"label.account.company":`Empresa`,
"label.account.come":`Bemvindo à Plataforma de Sucesso do Cliente Cloudcc. Nossa nova plataforma de relâmpagos fornece a solução mais rápida e completa, permitindo que você faça tudo com seu cliente no centro.  `,
"label.account.input.password":`Por favor, digite sua senha.`,
"label.account.emailobject.emaildetail.button.back":`Voltar`,
"label.account.title.itManager":`Gerente de TI`,
"label.account.Modified.successfully":`Mudança de senha com sucesso. Favor clicar em login novamente para retornar à página de login.`,
"label.account.only11":`Favor digitar pelo menos 7 dígitos.`,
"label.account.signin":`Login`,
"label.account.register":`Cadastrese para obter`,
"label.account.adopt":`A verificação passou!`,
"label.account.title.hrManager":`Gerente de Marketing/HR`,
"label.account.input.usernames":`Por favor, digite seu nome de usuário.`,
"label.account.only.Numbers":`O número de telefone só pode inserir números.`,
"label.account.title.salesManager":`Gerente de Vendas`,
"label.account.email":`Email`,
"label.account.click":`Clique`,
"label.account.and":`e`,
"label.account.mobile":`Telefone`,
"label.account.Privacy.policy":`Política de Privacidade`,
"label.account.password.isnull":`A senha não pode estar vazia.`,
"label.account.employees101":`101500 funcionários`,
"label.account.remember.name":`Lembrar nome de usuário`,
"label.account.Select.country":`Por favor, selecione seu país/região.`,
"label.account.distinguish":`A senha é sensível a maiúsculas e minúsculas.`,
"label.account.fillMobile":`Por favor, digite seu número de telefone.`,
"label.account.Preconfigured":`Relatórios e painéis de controle prédefinidos`,
"label.account.Select.job":`Por favor, selecione sua posição.`,
"label.account.employees21":`21100 funcionários`,
"label.account.username":`Nome de usuário`,
"label.account.Submit":`Enviar para`,
"label.account.confirm":`Confirmação de conta`,
"label.account.contact":`Contatenos`,
"label.account.country":`País`,
"label.account.username.isnull":`O nome de usuário não pode estar vazio.`,
"label.account.forget.password":`Esqueceu a senha?`,
"label.account.Loading":`Carregamento`,
"label.account.goon":`Continuar`,
"label.account.Register.trial":`Inscrevase para um teste gratuito`,
"label.account.title.other":`Outros`,
"label.account.surname":`Sobrenome`,
"label.account.Fail":`A verificação falhou, por favor tente novamente.`,
"label.account.Terms.service":`Termos de serviço`,
"label.account.Select.staff":`Por favor, selecione o número de funcionários.`,
"label.account.username.enter":`Por favor, digite seu sobrenome.`,
"label.account.trial":`Teste gratuito`,
"label.account.employees1":`120 funcionários`,
"label.account.Quick.registration":`ou acesso rápido`,
"label.account.tabpage.ok":`Confirme`,
"label.account.normal.reregister":`Login novamente`,
"label.account.batchadd.newbatchpage.save.em":`O formato do email está incorreto.`,
"label.account..user.title":`Cargo`,
"label.account.No.credit.card.required":`Não é necessário cartão de crédito.`,
"label.account.enterprise.Wechat":`WeCom`,
"label.account.sample.data":`Précarregar dados de amostra`,
"label.account.title.hobbies":`Passatempos`,
"label.account.platform":`Nova plataforma de CRM inteligente, móvel e digital`,
"label.account.sent":`Email enviado`,
"label.account.Confirm.password":`Por favor, confirme sua senha.`,
"label.account.fill":`Por favor, preencha tudo.`,
"label.account.user.resetpw":`Redefinir senha`,
"label.account.Spell":`Arraste o controle deslizante para completar o quebracabeça.`,
"label.account.identifying.code":`Por favor, digite o código de verificação.`,
"label.account.staff":`Empregados`,
"label.account.welcome":`Bemvindo ao CloudCC`,
"label.account.online.guidance":`Consultoria e instrução online`,
"label.account.login.CloudCC":`Login no CloudCC`,
"label.account.Company.name":`Por favor, insira o nome de sua empresa.`,
"label.account.title.generalManager":`Gerente Geral`,
"label.account.enter.user":`Para redefinir sua senha, por favor, digite seu nome de usuário CloudCC.`,
"label.account.sent.code":`O código de verificação foi enviado para`,
"label.account.pw.error.text":`As duas senhas que você inseriu não coincidiram.`,
"label.account.title.customerManager":`Gerente de atendimento ao cliente`,
"label.account.label.captcha":`Código de Verificação`,
"label.account.Trial.days":`Inscrevase para um teste gratuito por 30 dias`,
"label.account.please.enter.name":`Por favor, digite seu primeiro nome.`,
"label.account.inspect":`Se você não receber o email de confirmação do código de verificação, favor verificar o spam.`,
"label.account.User.rule":`O nome de usuário tem a forma de um endereço de email.`,
"label.account.go.Login":`Já tem uma conta?`,
"label.account.Reading.consent":`Eu concordo com o`,
"label.account.Change.Password2":`Mudança de senha`,
"label.account.Loginby":`Loginby`,
"label.account.apply.partner":`Inscrevase para se tornar um parceiro CloudCC`,
"label.account.MobileLogin":`Móvel`,
"label.account.Registration":``,
"label.account.Italy":`Itália`,
"label.account.view.details":`Ver detalhe`,
"label.account.accept":`Favor ler e aceitar os Termos de Serviço e a Política de Privacidade`,
"label.account.China.Mainland":`China Continental`,
"label.account.NewPassword":`Favor digitar a nova senha`,
"label.account.Mobile.not.empty":`O número móvel não pode estar vazio`,
"label.account.Please.select":`Favor escolher`,
"label.account.market.introduce":`Plataforma unificada de canais de marketing ajuda você a ganhar mais leads`,
"label.account.IOS":`Scan para download do aplicativo iOS`,
"label.account.failsend":`Falha no envio de`,
"label.account.UpperLowerlAphanumeric.character":`São solicitadas letras maiúsculas e minúsculas, números e símbolos`,
"label.account.New.Password1":`Nova senha`,
"label.account.succSend":`Um email foi enviado a você. Por favor, copie o código de verificação no email e redefina a senha.`,
"label.account.Account.mobile":`Número de conta ou celular`,
"label.account.Login.consent":`Ao fazer o login, você concorda em`,
"label.account.fillAreaCode":`Por favor, digite o código de área`,
"label.account.active.login":``,
"label.account.jp":`Japão`,
"label.account.partner.success1":`Você se registrou com sucesso como um parceiro da CloudCC.`,
"label.account.Reset":`Reinicialização`,
"label.account.no.user.info":``,
"label.account.EnterOldPass":`Por favor, digite a senha antiga`,
"label.account.Germany":`Alemanha`,
"label.account.title.content":`Inscrevase no CloudCC | 30 dias de teste grátis  Experimente agora!`,
"label.account.Taiwan":`Taiwan, China`,
"label.account.China.Station":`China`,
"label.account.sale.introduce":`A automação de vendas ajuda você a ganhar mais negócios`,
"label.account.Back":`Passo Anterior`,
"label.account.market":`Nuvem de Marketing`,
"label.account.section.company":`Detalhes da empresa`,
"label.account.check.email":`nosso executivo de contas entrará em contato com você em breve, por favor verifique seu email em tempo`,
"label.account.characters":`5 caracteres`,
"label.account.International":`Internacional`,
"label.account.britain":`Reino Unido`,
"label.account.reg.fail.msg":`A inscrição falhou. Por favor, tente novamente mais tarde.`,
"label.account.success.reg":`você se registrou com sucesso`,
"label.account.company.url":`Website`,
"label.account.CannotBeEmpty":`Não pode estar vazio`,
"label.account.phone.note":``,
"label.account.second":`{segundos depois`,
"label.account.Change.Password":`Altere sua senha`,
"label.account.Netherlands":`Países Baixos`,
"label.account.pleaseAgree":`Favor aceitar e concordar com os Termos de Serviço e Política de Privacidade`,
"label.account.copyright":`CloudCC Inc. Todos os direitos reservados`,
"label.account.letter":`1 letra`,
"label.account.remember":`Agora eu me lembro`,
"label.account.accountNum":`Conta`,
"label.account.code.not.empty":`A captcha não pode estar vazia`,
"label.account.Spain":`Espanha`,
"label.account.Alphanumeric":`Uma mistura de letras e números é necessária`,
"label.account.obtain":`Adquirir`,
"label.account.company.txt":`Favor adicionar seu nome, se você não tiver uma entidade comercial registrada`,
"label.account.con.browser":``,
"label.account.Android":`Scan para baixar o aplicativo Android`,
"label.account.title":`CloudCC Free Trial  CloudCC.com`,
"label.account.partner.success2":`Nossa vontade é chegar até você daqui a pouco.`,
"label.account.Macao":`Macau, China`,
"label.account.Brazil":`Brasil`,
"label.account.service.introduce":`O processo de serviço sem costura melhora a eficácia da resposta`,
"label.account.codeFailure":`Código de verificação inválido`,
"label.account.AccountLogin":`Conta`,
"label.account.pswComplexReqRules":`O comprimento mínimo da senha é {0}, e {1}.`,
"label.account.UpperLowerlAphanumeric":`As letras maiúsculas e minúsculas, e o número são solicitados`,
"label.account.active.username":``,
"label.account.rememberPassword":`Lembrar senha`,
"label.account.HongKong":`Hon Kong, China`,
"label.account.oldPass":`Senha antiga`,
"label.account.Data.storage":`Seus dados serão armazenados no centro de dados {0}.`,
"label.account.number":`1 número`,
"label.account.Poland":`Polônia`,
"label.account.service":`Nuvem de serviços`,
"label.account.Incorrect.format":`Formato ou número incorreto, favor entrar novamente`,
"label.account.remember.status":`Lembrese de mim`,
"label.account.sale":`Nuvem de vendas`,
"label.account.No.account.binding":`Nenhuma conta está vinculada. Favor entrar em contato com o administrador`,
"label.account.Russia":`Rússia`,
"label.account.France":`França`,
"label.account.partner.content":`A CloudCC é uma empresa global que está agora recrutando parceiros para explorar soluções em todas as indústrias. Venha e juntese a nosso programa de afiliados!`,
"label.account.reg.phone.already":`Esta organização já existe para este telefone. Faça o login ou use um telefone diferente para se inscrever.`,
"label.account.Confirm":`Confirmar nova senha`,
"label.account.VerificationLogin":`Verificação do Código`,
"label.account.USA":`Estados Unidos`,
"label.account.mobile.num":`Favor digitar o número do celular`,
"label.account.MinLength":`O comprimento mínimo da senha é`,
"label.account.change.your.password":`Favor mudar a senha agora, em vista da segurança`,
"label.account.new.password":`Digite uma nova senha para {0}. Certifiquese de incluir pelo menos:`,
"label.account.website.enter":`Por favor, digite o endereço oficial do site`,
"label.account.pswComplexIsZero":``
}